import { ReactNode } from 'react'
import { Spin } from '@nbit/arco'
import { ThemeEnum } from '@nbit/chart-utils'
import { useCommonStore } from '@/store/common'
import styles from './index.module.css'

interface SpinProps {
  isShow: boolean
  delay?: number
  tip?: string
  loading?: boolean
  icon?: ReactNode
  customBackground?: string
}

function FullScreenSpin({ isShow, customBackground, ...props }: SpinProps) {
  const { theme } = useCommonStore()
  return (
    <div
      className={`spin ${theme === ThemeEnum.dark ? 'dark-spin' : 'light-spin'} ${isShow ? 'flex' : 'hidden'} ${
        customBackground || 'bg-bg_color'
      } ${styles.scoped}`}
    >
      <Spin dot {...props} />
    </div>
  )
}

export default FullScreenSpin
